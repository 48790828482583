import styled from 'styled-components';
import Img from 'gatsby-image';

export const ImgContainer = styled(Img)`
  height: 142px;
  img {
    object-fit: fill !important;
  }
  @media screen and (max-width: 575px) {
    img {
      object-position: 0px 0px !important;
      object-fit: cover !important;
    }
  }
`;

export const Title = styled.div`
  background-color: #624963;
  position: relative;
  margin-right: 6px;
  h4 {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    color: #ffffff;
    text-transform: uppercase;

    ::after {
      position: absolute;
      right: -6px;
      top: 0;
      content: ' ';
      width: 6px;
      height: 100%;
      background: linear-gradient(
        to right bottom,
        #624963 45%,
        transparent 55%
      );
    }
  }
  @media screen and (max-width: 1200px) {
    h4 {
      font-size: 12px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 991px) {
    min-height: 56px;
    margin-inline-end: 12px;
    h4 {
      ::after {
        right: -12px;
        width: 12px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    min-height: auto;
    width: fit-content;
    h4 {
      ::after {
        right: -6px;
        width: 6px;
      }
    }
  }
`;

export const Copy = styled.p`
  font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #10384f;
  min-height: 63px;
  margin-top: 8px;

  @media screen and (max-width: 991px) {
    min-height: 84px;
  }
  @media screen and (max-width: 767px) {
    min-height: 128px;
  }
  @media screen and (max-width: 575px) {
    min-height: 42px;
  }
  @media screen and (max-width: 380px) {
    min-height: 63px;
  }
`;

export const ReadSec = styled.div`
  margin-bottom: 16px;
  a {
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    color: #624963 !important;
    text-decoration: none;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    ::before {
      background-image: url(/images/brown-single-line.png);
      content: ' ';
      width: 9px;
      height: 22px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
`;
