import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Gallery from 'components/gallery';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col } from 'reactstrap';
import YoutubeVideoPlayer from '../components//video-player/video-player';
import FindTrial from '../components/search/find-a-trial';
import { Fonts, Container, HomePage } from '../global.css';
import { IoMdArrowDropright } from 'react-icons/io';
import Modal from '../components/modal/modal';
import Redirect from '../components/redirect/redirect';
import HomeCards from '../components/home-cards/home-cards';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideVideo: true,
      isModalOpen: false,
      externalUrl: null,
    };
  }
  videoBtnClick = () => {
    this.setState({
      hideVideo: false,
    });
  };

  showModal() {
    this.setState({
      isModalOpen: true,
    });
  }
  hideModal() {
    this.setState({
      isModalOpen: false,
    });
  }
  componentDidUpdate() {
    if (!this.state.isModalOpen && typeof window !== 'undefined') {
      document.documentElement.style.overflow = 'auto';
    }
  }

  render() {
    let { data } = this.props;
    return (
      <Layout meta={data.homeJson.seo}>
        <Fonts>
          <HomePage>
            <Container>
              <div className="recruting-wrapper">
                <h2 className={'main-title'}>
                  {data.homeJson.recruitingTitle}
                </h2>
                <HomeCards cards={data.homeJson.cards} />
              </div>
              <h2 className={'main-title'}>{data.homeJson.findTitle}</h2>
              <FindTrial
                navigate={this.props.navigate}
                location={this.props.location}
                pageContext={this.props.pageContext}
              />
              <h2 className={'main-title'}>{data.homeJson.ctTitle}</h2>
              <Gallery
                items={data.homeJson.gallery}
                readMore={data.homeJson.readMore}
              />
              <h2 className={'main-title'}>{data.homeJson.participateTitle}</h2>
              <Row>
                <Col className="banner-col">
                  <div className={'banner-container'}>
                    {this.state.hideVideo ? (
                      <div className={'banner'}>
                        <Img
                          className={'banner-left'}
                          fluid={
                            data.homeJson.participateImg.childImageSharp.fluid
                          }
                          alt={data.homeJson.participateAlt}
                        />
                        <div className={'banner-right'}>
                          <h3>{data.homeJson.participateSecTitle}</h3>
                          <button
                            className={'video-btn'}
                            onClick={this.videoBtnClick}
                          >
                            {data.homeJson.videoBtn}
                            <span>
                              <IoMdArrowDropright />
                            </span>
                          </button>
                          <Link to={data.homeJson.participateUrl}>
                            {data.homeJson.readMore}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className={'banner-video'}>
                        <YoutubeVideoPlayer
                          title={data.homeJson.participateTitle}
                          width="100%"
                          height="100%"
                          code={data.homeJson.videoCodeYT}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <h2 id={'enhancingDiversity'} className={'main-title'}>
                {data.homeJson.enhancingDiversityTitle}
              </h2>
              <Row>
                <Col xs={12}>
                  <p style={{ marginBottom: '40px' }}>
                    {data.homeJson.enhancingDiversityContent}
                  </p>
                </Col>
              </Row>
              <h2 className={'main-title'}>{data.homeJson.expectTitle}</h2>
              <Row>
                <Col xs={12}>
                  <div className="expect-section">
                    <Row>
                      <Col md={6} sm={12} className="expect-left">
                        <Img
                          className="expect-img"
                          fluid={data.homeJson.expectImg.childImageSharp.fluid}
                          alt={data.homeJson.expectAlt}
                        />
                      </Col>
                      <Col md={6} sm={12} className="expect-right">
                        <div className="expect-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.homeJson.expectContent.childMarkdownRemark
                                  .html,
                            }}
                          />
                          <Link to={data.homeJson.expectUrl}>
                            {data.homeJson.readMore}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} className="patient-navigation-section">
                  <div className="patient-navigation-text">
                    <p>
                      {data.homeJson.patientNavigationContent.beforeLink}{' '}
                      <a
                        href={data.homeJson.patientNavigationContent.link.url}
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            externalUrl:
                              data.homeJson.patientNavigationContent.link.url,
                          });
                          this.showModal();
                        }}
                      >
                        {data.homeJson.patientNavigationContent.link.text}
                      </a>{' '}
                      {data.homeJson.patientNavigationContent.afterLink}
                    </p>
                  </div>
                </Col>
              </Row>
              {this.state.isModalOpen ? (
                <Modal
                  open={this.state.isModalOpen}
                  hideModal={() => this.hideModal()}
                  autoOpen={true}
                  type="redirect"
                >
                  <Redirect
                    hideModal={() => this.hideModal()}
                    url={this.state.externalUrl}
                  />
                </Modal>
              ) : null}
              <br />
              <h2 className={'main-title'}>{data.homeJson.aboutCtTitle}</h2>
              <div
                className="about-text"
                dangerouslySetInnerHTML={{
                  __html: data.homeJson.content.childMarkdownRemark.html,
                }}
              />
              <div className={'about-btn'}>
                <Link to={data.homeJson.aboutUrl}>
                  {data.homeJson.readMore}
                </Link>
              </div>
            </Container>
          </HomePage>
        </Fonts>
      </Layout>
    );
  }
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      recruitingTitle
      title
      findTitle
      ctTitle
      participateTitle
      expectTitle
      aboutCtTitle
      aboutUrl
      readMore
      videoBtn
      videoCodeYT
      participateSecTitle
      participateAlt
      participateImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      expectAlt
      expectImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      expectContent {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      patientNavigationContent {
        beforeLink
        link {
          text
          url
        }
        afterLink
        patientNavigatorParagraph
        logo {
          img {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link
          alt
        }
      }
      expectUrl
      enhancingDiversityTitle
      enhancingDiversityContent
      participateUrl
      gallery {
        title
        copy
        url
        altText
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      cards {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        altText
        description
        redirect
        id
        internalId
      }
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
