import React from 'react';
import PropTypes from 'prop-types';

const YoutubeVideoPlayer = props => {
  return (
    <iframe
      title={props.title}
      width={props.width}
      height={props.height}
      src={`https://www.youtube.com/embed/${props.code}?autoplay=1&rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

YoutubeVideoPlayer.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

YoutubeVideoPlayer.defaultProps = {
  title: 'YTVideoPlayer',
  code: '',
  width: '600',
  height: '400',
};

export default YoutubeVideoPlayer;
