import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import { Container } from './gallery.css';
import { Row, Col } from 'reactstrap';

const Gallery = ({ items, readMore }) => (
  <Container>
    <div className="gallery-col">
      <Row>
        {items.map((item, i) => (
          <Col sm={4} key={i}>
            <Item {...item} readMore={readMore} />
          </Col>
        ))}
      </Row>
    </div>
  </Container>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  readMore: PropTypes.string,
};

export default Gallery;
