import { Col } from 'reactstrap';
import styled from 'styled-components';

export const ImgWrapper = styled.div`
  height: 232px;
  .gatsby-image-wrapper {
    height: 100%;
  }
  .cancer,
  .heart {
    img {
      object-position: 70% !important;
    }
  }
  .eye_disorders {
    img {
      object-position: 60% !important;
    }
  }
  .kidney {
    img {
      object-position: 95% !important;
    }
  }
  .womens_health {
    img {
      object-position: 40% !important;
    }
  }

  img {
    object-fit: cover !important;
    object-position: 50% !important;
  }
`;

export const CustomCol = styled(Col)`
  padding-bottom: 30px;
  @media screen and (min-width: 960px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  a {
    text-decoration: none;
    color: #8bca43;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Text = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  background-color: #275f7c;
  position: relative;
  padding: 0 18px 0 16px;
  color: #8bca43;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:after {
    width: 0;
    height: 0;
    border-bottom: 68px solid #fff;
    border-left: 18px solid #275f7c;
    content: ' ';
    position: absolute;
    right: 0px;
    top: 0;
    margin-top: 0;
    display: block;
  }
`;
