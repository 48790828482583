import React from 'react';
import PropTypes from 'prop-types';
import { Title, Copy, ReadSec, ImgContainer } from './item.css';
import { Link } from 'gatsby';

const Item = ({ title, copy, image, altText, readMore, url }) => (
  <figure>
    <ImgContainer
      fluid={image ? image.childImageSharp.fluid : {}}
      alt={altText}
    />
    <figcaption>
      <Title>
        <h4>{title}</h4>
      </Title>
      <Copy>{copy}</Copy>
      <ReadSec>
        <Link to={url}>{readMore}</Link>
      </ReadSec>
    </figcaption>
  </figure>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.object.isRequired,
  altText: PropTypes.string,
  readMore: PropTypes.string,
};

export default Item;
