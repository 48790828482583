import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  .gallery-col {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    padding: 0 15px;
  }
  @media screen and (max-width: 767px) {
    .gallery-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .gallery-col {
      padding: 0;
    }
  }
`;
